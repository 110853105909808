// src/components/GalleryModal.js
import React from 'react';
import Modal from 'react-modal';
import './GalleryModal.css';

Modal.setAppElement('#root'); // Set the root element for accessibility

function GalleryModal({ isOpen, onClose, image }) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="gallery-modal"
      overlayClassName="gallery-overlay"
      contentLabel="Gallery Modal"
    >
      <img src={image} alt="Gallery" className="modal-image" />
    </Modal>
  );
}

export default GalleryModal;