// src/components/Header.js
import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

function Header() {
  return (
    <header>
      <div className="header-content">
        <img src={process.env.PUBLIC_URL + "/images/jakey_header.png"} alt="Header" className="header-image" />
        <h1>Jakey Floofle</h1>
      </div>
      <nav>
        <ul>
          <li><Link to={process.env.PUBLIC_URL + "/about"}>About Me</Link></li>
          <li><Link to={process.env.PUBLIC_URL + "/projects"}>Projects</Link></li>
          <li><Link to={process.env.PUBLIC_URL + "/contact"}>Contact & Socials</Link></li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;