// src/components/Contact.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTelegram, faGithub, faDiscord, faSteam, faTwitter } from '@fortawesome/free-brands-svg-icons';
import './Contact.css';

function Contact() {
  return (
    <section className="contact">
      <h2>Contact</h2>
      <div className="contact-method">
        <FontAwesomeIcon icon={faTelegram} size="2x" />
        <a href="https://t.me/JakeyF" target="_blank" rel="noopener noreferrer">JakeyF</a>
      </div>
      <div className="contact-method">
        <FontAwesomeIcon icon={faDiscord} size="2x" />
        <a href="#discord">jakeyf_</a>
      </div>
      <div className="contact-method">
        <FontAwesomeIcon icon={faTwitter} size="2x" />
        <a href="https://twitter.com/JakeyF_">JakeyF_</a>
      </div>

      <br/>
      
      <div className="contact-method">
        <FontAwesomeIcon icon={faGithub} size="2x" />
        <a href="https://github.com/Jakey-F" target="_blank" rel="noopener noreferrer">Jakey-F</a>
      </div>
      <div className="contact-method">
        <FontAwesomeIcon icon={faSteam} size="2x" />
        <a href=" 	https://steamcommunity.com/profiles/76561198077065189" target="_blank" rel="noopener noreferrer">Jakey</a>
      </div>
      <div className="contact-method">
      <svg xmlns="http://www.w3.org/2000/svg" width="2.25em" height="2.25em" viewBox="-2 -2 24 24"><g fill="currentColor"><path d="M10 18a8 8 0 1 0 0-16a8 8 0 0 0 0 16m0 2C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10s-4.477 10-10 10"/><path d="M13.427 13.148v-5h-5v-.312c0-.517.42-.938.938-.938h.937V5.023h-.937a2.816 2.816 0 0 0-2.813 2.813v.312h-1.25v1.875h1.25v3.125h-1.25v1.875h4.375v-1.875h-1.25v-3.125h3.143v3.125h-1.268v1.875h4.375v-1.875z"/><circle cx="12.402" cy="5.971" r="1.001"/></g></svg>
        <a href="https://fiverr.com/ja4ake" target="_blank" rel="noopener noreferrer">JA4AKE</a>
      </div>
    </section>
  );
}

export default Contact;