// src/components/Projects.js
import React from 'react';
import { Link } from 'react-router-dom';
import './Projects.css';

import projects from "./projects.json";

// const projects = [
//   {
//     id: 1,
//     title: 'Jakey as Sniper',
//     shortname: 'jakeysniper',
//     shortDescription: "An asset replacement for Team Fortress 2",
//     image: '/images/projects/tf2_jakeysniper/preview.png',
//     icon_purpose: '/images/jakey_header.png',
//     icon_game: '/images/tf2.png'
//   },
//   {
//     id: 2,
//     title: 'LethalCombine',
//     shortname: "lethalcombine",
//     shortDescription: "A mod for Lethal Company",
//     image: '/images/projects/lc_lethalcombine/preview.png',
//     icon_purpose: '/images/jakey_header.png',
//     icon_game: '/images/lethalcompany.png'
//   },
//   {
//     id: 3,
//     title: 'Black Mesa Medkit',
//     shortname: "bmsmedkit",
//     shortDescription: "A SWEP for Garry's Mod",
//     image: '/images/projects/gm_blackmesamedkit/preview.png',
//     icon_purpose: '/images/jakey_header.png',
//     icon_game: '/images/gmod.png'
//   },
//   {
//     id: 4,
//     title: 'Source Radio',
//     shortname: "sourceradio",
//     shortDescription: "A radio for Garry's Mod with music from various Source games",
//     image: '/images/projects/gm_sourceradio/preview.png',
//     icon_purpose: '/images/jakey_header.png',
//     icon_game: '/images/gmod.png'
//   },
//   {
//     id: 5,
//     title: 'Bork Buchwieser',
//     shortname: "borkbuchwieser",
//     shortDescription: "Commission to add Bork Buchwieser to Project Zomboid",
//     image: '/images/projects/pz_borkbuchwieser/preview.png',
//     icon_purpose: '/images/discord.png',
//     icon_game: '/images/pz.png'
//   },
//   {
//     id: 6,
//     title: 'Smooth Criminal',
//     shortname: "smoothcriminal",
//     shortDescription: "Recreation of a Traitor weapon for Trouble in Terrorist Town",
//     image: '/images/projects/gm_smoothcriminal/preview.png',
//     icon_purpose: '/images/jakey_header.png',
//     icon_game: '/images/gmod.png'
//   },
//   {
//     id: 7,
//     title: 'SCP: SL Warhead',
//     shortname: "scpslwarhead",
//     shortDescription: "Recreation of SCP: Secret Laboratory's alpha warhead",
//     image: '/images/projects/gm_scpslwarhead/preview.png',
//     icon_purpose: '/images/jakey_header.png',
//     icon_game: '/images/gmod.png'
//   }
//   // Add more projects as needed
// ];

function Projects() {
  return (
    <section>
      <h2>Projects</h2>
      <div className="projects-grid">
        {projects.map((project, index) => (
          <Link key={project.id} to={`${process.env.PUBLIC_URL}/projects/${project.shortname || project.id}`} className="project-tile">
            {project.icon_purpose && <img src={`${process.env.PUBLIC_URL}${project.icon_purpose}`} alt="Icon" className="icon" />}
            {project.icon_game && <img src={`${process.env.PUBLIC_URL}${project.icon_game}`} alt="Icon" className="icon-below" />}
            <img src={`${process.env.PUBLIC_URL}${project.image}`} alt={project.title} />
            <h3>{project.name}</h3>
            <p className="short-description">{project.shortDescription}</p>
          </Link>
        ))}
      </div>
    </section>
  );
}

export default Projects;