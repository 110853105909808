// src/App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import About from './components/About';
import Projects from './components/Projects';
import ProjectDetails from './components/ProjectDetails';
import Contact from './components/Contact';

function App() {
  const [tab, setTab] = useState('about');

  return (
    <Router>
      <div className="App">
        <Header setTab={setTab} />
        <Routes>
          <Route path={process.env.PUBLIC_URL + "/"} element={<About />} />
          <Route path={process.env.PUBLIC_URL + "/about"} element={<About />} />
          <Route path={process.env.PUBLIC_URL + "/projects"} element={<Projects />} />
          <Route path={process.env.PUBLIC_URL + "/projects/:id"} element={<ProjectDetails />} />
          <Route path={process.env.PUBLIC_URL + "/contact"} element={<Contact />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;