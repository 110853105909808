// src/components/About.js
import React from 'react';
import './About.css';

function About() {
  return (
    <section>
      <h2>About Me</h2>
      <p>Heyo! My name's Jakey. I'm a freelance back-end developer situated in Belgium, currently studying IT.<br/>
      I speak English and Dutch, with a bit of Norwegian and a little French.<br/>
      <br/>
      I mainly specialise in webapps and Garry's Mod addons, mainly in Lua.
      I also have quite a bit of experience using C# / .NET, PHP and Java, and I'm rather familiar with the Source Engine.
      <br/>
      <br/>
      In the Projects tab you can have a look of some things I've done, and probably some more over on my Twitter, which you can find in the Contacts & Socials tab.
      </p>
    </section>
  );
}

export default About;