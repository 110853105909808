// src/components/ProjectDetails.js
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import './ProjectDetails.css';
import GalleryModal from './GalleryModal';
import projects from "./projects.json";
// const projects = [
//     {
//         id: 1,
//         shortname: "jakeysniper",
//         title: 'Team Fortress 2 | Jakey as Sniper | Personal Project',
//         bannerImage: '/images/projects/tf2_jakeysniper/banner.png',
//         description: 'An asset replacement for Team Fortress 2; I put Jakey, my sona, in the game as Sniper, complete with facial animation. Cosmetics are a little rough, but there\'s not much I can do about that without remodelling every individual cosmetic to fit Jakey.\n\nThis was mostly just something to practice Blender with, but I feel like it turned out really well in the end (And actually looks pretty good, too.)',
//         gallery: [
//             '/images/projects/tf2_jakeysniper/character.png',
//             '/images/projects/tf2_jakeysniper/smug.png',
//             '/images/projects/tf2_jakeysniper/laugh.png',
//             '/images/projects/tf2_jakeysniper/rps.png',
//             '/images/projects/tf2_jakeysniper/dead.png',
//             '/images/projects/tf2_jakeysniper/fistbump.png',
//             '/images/projects/tf2_jakeysniper/player.png',
//             '/images/projects/tf2_jakeysniper/Jakey_RED.png',
//             '/images/projects/tf2_jakeysniper/Jakey_RED.png',
//         ]
//     },
//     {
//         id: 2,
//         shortname: "lethalcombine",
//         title: 'Lethal Company | Lethal Combine | Personal Project',
//         bannerImage: '/images/projects/lc_lethalcombine/banner.png',
//         description: 'A mod for Lethal Company; Adding Half-Life 2\'s Combine Forces to the game. Including Metrocops, Overwatch and Elites, complete with SFX for emoting, injury and death, and even glow-in-the-dark eyes.\n\nAnother little practice project that turned out pretty nicely.\n\nThis mod is available on Thunderstore (See Links section)',
//         gallery: [
//             '/images/projects/lc_lethalcombine/rat.png',
//             '/images/projects/lc_lethalcombine/suits.png',
//             '/images/projects/lc_lethalcombine/models.png',
//         ],
//         links: [
//             { name: 'Thunderstore Listing', url: 'https://thunderstore.io/c/lethal-company/p/JakeysCoolModdingTeam/HalfLife2_LethalCombine/' }
//         ]
//     },
//     {
//         id: 3,
//         title: 'Garry\'s Mod | Black Mesa Medkit | Personal Project',
//         shortname: "bmsmedkit",
//         bannerImage: '/images/projects/gm_blackmesamedkit/banner.png',
//         description: 'An asset replacement for Garry\'s Mod; Adding Black Mesa\'s Medkit as a SWEP to the game. This is a complete SWEP of its own, not replacing the default Medkit SWEP that comes with Garry\'s Mod',
//         gallery: [
//             '/images/projects/gm_blackmesamedkit/firstperson.png',
//             '/images/projects/gm_blackmesamedkit/thirdperson.png',
//             '/images/projects/gm_blackmesamedkit/thirdperson2.png',
//         ],
//         links: [
//             { name: 'Steam Workshop Listing', url: 'https://steamcommunity.com/sharedfiles/filedetails/?id=3233661422' }
//         ]
//     },
//     {
//         id: 4,
//         title: 'Garry\'s Mod | Source Radio | Personal Project',
//         shortname: "sourceradio",
//         bannerImage: '/images/projects/gm_sourceradio/banner.png',
//         description: 'A radio containing soundtracks from various Source and Source 2 games. This originally started out as a radio called "Team Fortress 2" radio, which only contained songs from Team Fortress 2. With the success of that addon, I decided to make it a bit more inclusive, opting to include music from (almost) all of Valve\'s games; Half-Life, Half-Life 2 (including both games\' expansions/episodes), Left 4 Dead and 2 and Portal 1 and 2.\n\nI originally planned to add music from popular Sourcemods as well, but I haven\'t gotten around to that yet.',
//         gallery: [
//             '/images/projects/gm_sourceradio/radio.png',
//             '/images/projects/gm_sourceradio/menu.png',
//             '/images/projects/gm_sourceradio/songselect.png',
//         ],
//         links: [
//             { name: 'Steam Workshop Listing', url: 'https://steamcommunity.com/sharedfiles/filedetails/?id=1208169143' }
//         ]
//     },
//     {
//         id: 5,
//         title: 'Project Zomboid | Bork Buchwieser | Discord Commission',
//         shortname: "borkbuchwieser",
//         bannerImage: '/images/projects/pz_borkbuchwieser/banner.png',
//         description: 'A mod for Project Zomboid, adding Bork Buchwieser to the game as an extension to Anthro Survivors. This was a commission over Discord. Available as part of Bueno Survivors, see Links below',
//         gallery: [
//             '/images/projects/pz_borkbuchwieser/substance.png',
//             '/images/projects/pz_borkbuchwieser/substance2.png',
//             '/images/projects/pz_borkbuchwieser/character.png',
//             '/images/projects/pz_borkbuchwieser/RUNFORYOURLIFE.png',
//             '/images/projects/pz_borkbuchwieser/dance.gif',
//             '/images/projects/pz_borkbuchwieser/dance2.gif'
//         ],
//         links: [
//             { name: 'Steam Workshop Listing', url: 'https://steamcommunity.com/sharedfiles/filedetails/?id=3045662000' }
//         ]
//     },
//     {
//         id: 6,
//         title: 'Garry\'s Mod | Smooth Criminal | Personal Project',
//         shortname: "smoothcriminal",
//         bannerImage: '/images/projects/gm_smoothcriminal/banner.png',
//         description: 'A Traitor weapon for Garry\'s Mod\'s Trouble in Terrorist Town; a dart gun with 2 shots, which will make victims uncontrollably dance and sing Michael Jackson\'s Smooth Criminal until they inevitably die.\n\nThis was made for my personal TTT server with my friends, but I decided to put it on the workshop anyway.',
//         gallery: [
//             '/images/projects/gm_smoothcriminal/dance.png'
//         ],
//         links: [
//             { name: 'Steam Workshop Listing', url: 'https://steamcommunity.com/sharedfiles/filedetails/?id=3239615849' },
//             { name: 'YouTube Video', url : 'https://www.youtube.com/watch?v=R6MWtrvw1dY' }
//         ]
//     },
//     {
//         id: 7,
//         title: 'Garry\'s Mod | SCP: SL Warhead | Personal Project',
//         shortname: "scpslwarhead",
//         bannerImage: '/images/projects/gm_scpslwarhead/banner.png',
//         description: 'A recreation of SCP: Secret Laboratory\'s Alpha Warhead in Garry\'s Mod. Not much to say about this one, really. Boom?',
//         gallery: [
//             '/images/projects/gm_scpslwarhead/items.png',
//             '/images/projects/gm_scpslwarhead/imminentboom.png'
//         ],
//         links: [
//             { name: 'Steam Workshop Listing', url: 'https://steamcommunity.com/sharedfiles/filedetails/?id=2514885332' },
//             { name: 'YouTube Video', url : 'https://www.youtube.com/watch?v=AtJQXlw_gaQ' }
//         ]
//     },
// Add more projects as needed
//];

function ProjectDetails() {
    const { id } = useParams();
    console.log(id);
    let project = projects.find(p => p.id === parseInt(id));
    if (!project) project = projects.find(p => p.shortname === id);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    const openModal = (image) => {
        setSelectedImage(image);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setSelectedImage(null);
        setIsModalOpen(false);
    };

    return (
        <div className="project-details">
            <header className="project-header">
                <img src={process.env.PUBLIC_URL + project.bannerImage} alt={project.title} className="project-banner" />
                <h1>{project.title}</h1>
            </header>
            <section className="project-description">
                <p>{project.description}</p>
            </section>
            {project.links && (
                <section className="project-links">
                    <h2>Links</h2>
                    <ul>
                        {project.links.map((link, index) => (
                            <li key={index}>
                                <a href={link.url} target="_blank" rel="noopener noreferrer">
                                    {link.name}
                                </a>
                            </li>
                        ))}
                    </ul>
                </section>
            )}
            <section className="project-gallery">
                {project.gallery.map((image, index) => (
                    <img
                        key={index}
                        src={process.env.PUBLIC_URL + image}
                        alt={`Project ${index + 1}`}
                        onClick={() => openModal(image)}
                        className="gallery-image"
                    />
                ))}
            </section>
            <GalleryModal isOpen={isModalOpen} onClose={closeModal} image={selectedImage} />
        </div>
    );
}

export default ProjectDetails;